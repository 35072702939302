import type { Button } from '@repo/ui/components/Button.js'
import { cn } from '@repo/ui/utils/utils'
import type { ComponentProps } from 'react'
import type { z } from 'zod'
import { useForm } from '~/hooks/useForm'
import { LoginSchema } from '~/routes/_auth.login/routeConfig'
import { Form } from './form/Form'

export const LoginButton = ({
  className,
  provider,
  ...props
}: ComponentProps<typeof Button> & {
  provider: z.infer<typeof LoginSchema>['provider']
}) => {
  const [form, fields] = useForm({
    schema: LoginSchema,
    defaultValue: { provider, timezone: Intl.DateTimeFormat().resolvedOptions().timeZone },
    fetcherKey: `${LoginSchema.shape.intent.value}_${provider}`
  })

  return (
    <Form form={form} action="/login">
      <Form.HiddenInput name={fields.provider.name} />
      <Form.HiddenInput name={fields.timezone.name} />
      <Form.SubmitButton
        icon={provider === 'google' ? 'google-color' : 'microsoft-color'}
        variant="outline"
        className={cn('w-full', className)}
        {...props}
      >
        <span>
          Sign in with <span className="capitalize">{provider}</span>
        </span>
      </Form.SubmitButton>
    </Form>
  )
}
